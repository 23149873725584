import React from "react";
// nodejs library that concatenates classes
import classNames from "classnames";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";

// core components
import Header from "components/Header/Header.jsx";
import Footer from "components/Footer/Footer.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import HeaderLinks from "components/Header/HeaderLinks.jsx";

import landingPageStyle from "assets/jss/material-kit-react/views/landingPage.jsx";
import productStyle from "assets/jss/material-kit-react/views/landingPageSections/productStyle.jsx";

import { CssBaseline, Typography } from "@material-ui/core";

import NewsLetterSection from "../LandingPage/Sections/NewsLetterSection";
import FooterSection from "../LandingPage/Sections/FooterSection";
import partnersImg from "assets/img/partners.png";

import partnersImg1 from "assets/img/partners/1.jpg";
import partnersImg2 from "assets/img/partners/2.jpg";
import partnersImg3 from "assets/img/partners/3.jpg";
import partnersImg4 from "assets/img/partners/4.jpg";
import partnersImg5 from "assets/img/partners/5.jpg";
import partnersImg6 from "assets/img/partners/6.jpg";
import partnersImg7 from "assets/img/partners/7.jpg";
import partnersImg8 from "assets/img/partners/8.jpg";
import partnersImg9 from "assets/img/partners/9.jpg";
import partnersImg10 from "assets/img/partners/10.jpg";
import partnersImg11 from "assets/img/partners/11.jpg";


const dashboardRoutes = [];

class PartnersPage extends React.Component {
  render() {
    const { classes, ...rest } = this.props;
    return (
      <div>
        <Header
          color="transparent"
          routes={dashboardRoutes}
          brand="AL MIDIAFF INTERNATIONAL SUPPLIES"
          // brand="AL MIDIAFF INTERNATIONAL Co. LLC"
          // brand="AL MIDIAFF"
          rightLinks={<HeaderLinks />}
          fixed
          changeColorOnScroll={{
            height: 400,
            color: "white"
          }}
          {...rest}
        />
        <CssBaseline />
        <div className={classNames(classes.main, classes.mainRaised)}>
          <div className={classes.section} style={{ padding: "0 15%" }}>
            <div style={{ height: 160 }}></div>

            <div className={classes.container}>
              <GridContainer aria-label="breadcrumb">

                <a href="/">Home /</a>
                &nbsp;
                <a href="#">Partners</a>

              </GridContainer>
              <GridContainer>
                <GridItem xs={12} sm={9} md={8} style={{}}>
                  <h1 className={classes.title} style={{ color: "#6C648B" }}>Partners</h1>
                </GridItem>
              </GridContainer>
            </div>
            <div style={{ height: 40 }}></div>
            <div style={{ background: "#fff", maxWidth: "100%" }}>
              <div className={classes.section} style={{}}>
                <GridContainer>
                  <GridItem xs={12} sm={12} md={11} style={{}}>
                    <Typography align="justify">
                      Al Midiaff International Co. is proud to have worked with clients from both public and private sectors.
                      We consider the trust of our clients,for which we have worked hard, our most valuable asset.
                      We will continue to safeguard this asset by raising the bar in professionalism,Quality and Safety of our products and services
                      while keeping cost down so our customer can do more with their assets.
                    </Typography>
                  </GridItem>
                </GridContainer>
              </div>
              <div style={{ height: 80 }}></div>
              <div className={classes.section} style={{}}>
                {/* <img
                  src={partnersImg}
                  style={{ width: "100%", padding: "10px" }}
                /> */}
                <GridContainer>
                  <GridItem xs={6} sm={4} md={3} style={{}}>
                    <img
                      src={partnersImg1}
                      style={{ width: "100%", padding: "10px" }}
                    />
                  </GridItem>
                  <GridItem xs={6} sm={4} md={3} style={{}}>
                    <img
                      src={partnersImg2}
                      style={{ width: "100%", padding: "10px" }}
                    />
                  </GridItem>
                  <GridItem xs={6} sm={4} md={3} style={{}}>
                    <img
                      src={partnersImg3}
                      style={{ width: "100%", padding: "10px" }}
                    />
                  </GridItem>
                  <GridItem xs={6} sm={4} md={3} style={{}}>
                    <img
                      src={partnersImg4}
                      style={{ width: "100%", padding: "10px" }}
                    />
                  </GridItem>
                  <GridItem xs={6} sm={4} md={3} style={{}}>
                    <img
                      src={partnersImg5}
                      style={{ width: "100%", padding: "10px" }}
                    />
                  </GridItem>
                  <GridItem xs={6} sm={4} md={3} style={{}}>
                    <img
                      src={partnersImg6}
                      style={{ width: "100%", padding: "10px" }}
                    />
                  </GridItem>
                  <GridItem xs={6} sm={4} md={3} style={{}}>
                    <img
                      src={partnersImg7}
                      style={{ width: "100%", padding: "10px" }}
                    />
                  </GridItem>
                  <GridItem xs={6} sm={4} md={3} style={{}}>
                    <img
                      src={partnersImg8}
                      style={{ width: "100%", padding: "10px" }}
                    />
                  </GridItem>
                  <GridItem xs={6} sm={4} md={3} style={{}}>
                    <img
                      src={partnersImg9}
                      style={{ width: "100%", padding: "10px" }}
                    />
                  </GridItem>
                  <GridItem xs={6} sm={4} md={3} style={{}}>
                    <img
                      src={partnersImg10}
                      style={{ width: "100%", padding: "10px" }}
                    />
                  </GridItem>
                  <GridItem xs={6} sm={4} md={3} style={{}}>
                    <img
                      src={partnersImg11}
                      style={{ width: "100%", padding: "10px" }}
                    />
                  </GridItem>
                </GridContainer>
              </div>
              <div className={classes.section} style={{}}>

              </div>
            </div>
          </div>
          <div style={{ background: "#6C648B", width: "100%" }}>
            <div className={classes.container} >
              <NewsLetterSection />
            </div>
          </div>

          <div style={{ background: "#B6A19E", minHeight: "2em" }}></div>
          <FooterSection />


        </div>
        <Footer />
      </div>
    );
  }
}

export default withStyles(landingPageStyle, productStyle)(PartnersPage);
